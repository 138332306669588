.media-supply-policy {

	&__title {
		@include vw-font-size(18px);
	    font-weight: bold;
	    line-height: 1.4;
	    margin:0 auto 20px;

	    @include media-breakpoint-up(md)  {
	        @include vw-font-size(32px);
	    }
	}

	&__info {
		margin-bottom:30px;

		@include media-breakpoint-up(md)  {
			margin-bottom:45px;
		}
	}

	&__table {
		@include vw-font-size(15px);
		border-collapse: collapse;
		margin-bottom:20px;
		width:100%;

	    @include media-breakpoint-up(md)  {
	        @include vw-font-size(16px);
	    }

		tr {
			border-bottom: 1px solid #979797;

			&:first-child { border-bottom:0; }
		}

		th {
			&:last-child {
				display:none;

				@include media-breakpoint-up(md)  {
					display:table-cell;
				}
			}
		}

		td {
			min-width:150px;
			padding: 15px 0 10px;

			&:last-child {
				display:none;

				@include media-breakpoint-up(md)  {
					display:table-cell;
				}
			}
		}
	}

	&__text-small {
		p {
			@include vw-font-size(15px);

		    @include media-breakpoint-up(md)  {
		        @include vw-font-size(16px);
		    }
		}
	}
}