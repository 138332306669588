// ==========================================================================
// Product Table
// ==========================================================================

// 1.Config


// 2.Base

.product-table {
	$block: '.product-table';
  	
  	&__row {
		@extend %clearfix;
		padding: 15px 10px;
		border-bottom: solid 1px #d8d8d8;

		&--isDriver {
			background:#fff;
			border-bottom:0;
			padding: 20px 10px;

			.product-table__col:first-child {
				line-height:40px;
			}

			.product-table__col:last-child {
				line-height:40px;
				margin-top:0;
			}
		}

		&--header {
			font-weight:400;
			display:none;
			
			@include media-breakpoint-up(md)  {
				display:block;
			}
		}

  	}

  	&__col {
		float:left;
		font-size: 16px;
		line-height:1.6;
		padding:0 5px;
		position:relative;

		@media (max-width:767px) {
			padding:0;
			width:100% !important;
		}
		
		&--category {
			font-size:18px;
			font-weight:400;
			float:none;

			@include media-breakpoint-up(lg)  {
				padding-right:5px;
				overflow:hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				float:left;
				width:270px;
				width:225px;
				margin-top: -2px;
			}
		}

		&--name {
			width:calc(100% - 110px);
			padding-right:10px;
			@extend %ellipsis;

			@include media-breakpoint-up(md)  {
			width:270px;
			}
		}

		&--info {
			text-transform:uppercase;
			float:none;
			display:inline-block;
			font-size:11px;

			@include media-breakpoint-up(md)  {
				width:8%;
				float:left;
				font-size:16px;
			}
		}

		&--date {

			//background: aqua;

			float:none;
			display:inline-block;
			font-size:11px;

			@include media-breakpoint-up(md)  {
				width:11%;
				float:left;
				font-size:16px;
			}
		}


		&--download {
			float:right;
			width:120px;
			text-align:right;
			font-size:20px;  

			@include media-breakpoint-up(lg)  {
				margin-top: -8px;
			}	

			a.disabled {
				pointer-events:none;
				opacity:0.2;
			}

			.select2 { margin-right:5px; }
			
		}

		&--ref {
			font-size:16px;
			font-weight:700;

			@include media-breakpoint-up(md)  {
				font-weight:300;
			}
		}
  	}

  	&__group {
  		#{$block}__row:not(:last-child) {
  			border-bottom:none;
  			padding-bottom:0px;

  			@include media-breakpoint-up(lg)  {
				padding-bottom:15px;
  			}
  		}
		
		#{$block}__row:not(:first-child) { 
			.product-table__col--category {
				opacity:0;
				display:none;
				@include media-breakpoint-up(lg)  {
					display:block;
				}
			}
		}

		#{$block}__row:first-child { 
			.product-table__col--category {
				margin-bottom:15px;
				@include media-breakpoint-up(lg)  {
					margin-bottom:0;
				}
			}
		}
		
  	}

  	&--four-col {
  		.product-table__col {
  			float:left;
  			padding:0 7px 10px;
  			width:100%;

  			@media (min-width:600px) {
  				padding-bottom:0;
  				width:25%;
  			}
  		}

  		.product-table__col--category {
  			display:none;
  			font-weight:normal;

  			@media (min-width:600px) {
  				display:block;
  			}
  		}
  	}


  	&--specifications {

  		#{$block}__col {
  			float:none;

  			@include media-breakpoint-up(md)  {
				float:left;
				width:calc(100% - 300px);
  			}

  			&:first-child {
  				font-weight:bold;

  				@include media-breakpoint-up(md)  {
					font-weight:normal;
					width:300px;
					padding-right:15px;
  				}
  			}
  		}
  	}

  	&--consumables {


  		#{$block}__col {
  			float:none;
  			font-size:14px;

  			@include media-breakpoint-up(md)  {
				float:left;
				width:20%;
				font-size:14px;
  			}

  			span {
  				font-weight:300;
  				@include media-breakpoint-up(md)  {
					display:none;
  				}
  			}

  			
  		}
  	}

  	&--product-range {
		img {
			display:block;
			margin:10px 0;
			max-width:140px;
		}
	}
}

section.product-extended {
	.product-table { padding:0; }
}