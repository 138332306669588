// ==========================================================================
// Product Extended
// ==========================================================================

// 1.Config


// 2.Base

.product-extended {
    

    &__block {
    	padding:40px 0;
        position: relative;

    	&--gray {
    		background-color: rgba(233, 233, 233, 0.5);
    	}

        &--line {
            border-top:1px solid rgba(233, 233, 233, 0.5);
        }
    	
    }

    &__container {
    	padding:0 15px;
	    margin:0 auto;
	    max-width:905px;

	    @include media-breakpoint-up(md)  {
	        padding:0 30px;
	    }

	    &--extra {
	    	max-width:1460px;
	    }
    }

    &__title {
    	margin:0 0 15px;
    	line-height:1.4;

        @include media-breakpoint-up(md)  {
            margin:0 0 25px;
        }

    	&--centered {
    		text-align:center;
    	}
    }


    &__info {
    	margin-top:40px;
		

    	p {
    		margin:0 0 20px;

    		&:last-child {
    			margin:0;
    		}	

    		a {
    			color:$primary;
    			font-weight:bold;

    			&:hover {
    				text-decoration:underline;
    			}
    		}
    	}

    	&--small {

    	}
    }

    &__video {
    	margin-top:20px;
    }
}