@font-face {
  font-family: 'mitsubishi-font';
  src: url('../fonts/mitsubishi-font.eot?38100062');
  src: url('../fonts/mitsubishi-font.eot?38100062#iefix') format('embedded-opentype'),
       url('../fonts/mitsubishi-font.woff2?38100062') format('woff2'),
       url('../fonts/mitsubishi-font.woff?38100062') format('woff'),
       url('../fonts/mitsubishi-font.ttf?38100062') format('truetype'),
       url('../fonts/mitsubishi-font.svg?38100062#mitsubishi-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'mitsubishi-font';
    src: url('../font/mitsubishi-font.svg?38100062#mitsubishi-font') format('svg');
  }
}
*/
 
 [class^="ico-"]:before, [class*=" ico-"]:before {
  font-family: "mitsubishi-font";
  font-style: normal;
  font-weight: normal;
  speak: none;
  
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.ico-arrow-left:before { content: '\e800'; } /* '' */
.ico-arrow-right:before { content: '\e801'; } /* '' */
.ico-check:before { content: '\e802'; } /* '' */
.ico-close:before { content: '\e803'; } /* '' */
.ico-down:before { content: '\e804'; } /* '' */
.ico-download:before { content: '\e805'; } /* '' */
.ico-facebook:before { content: '\e806'; } /* '' */
.ico-left:before { content: '\e807'; } /* '' */
.ico-linkedin:before { content: '\e808'; } /* '' */
.ico-phone:before { content: '\e809'; } /* '' */
.ico-minus:before { content: '\e80a'; } /* '' */
.ico-play:before { content: '\e80b'; } /* '' */
.ico-plus:before { content: '\e80c'; } /* '' */
.ico-right:before { content: '\e80d'; } /* '' */
.ico-search:before { content: '\e80e'; } /* '' */
.ico-twitter:before { content: '\e80f'; } /* '' */
.ico-up:before { content: '\e810'; } /* '' */
.ico-whatsapp:before { content: '\e811'; } /* '' */
.ico-white-mailto:before { content: '\e812'; } /* '' */
.ico-minimize:before { content: '\e814'; } /* '' */
.ico-maximize:before { content: '\e815'; } /* '' */
.ico-external:before { content: '\e816'; } /* '' */
.ico-mailto:before { content: '\e817'; } /* '' */



/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v30-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/roboto/roboto-v30-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/roboto/roboto-v30-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/roboto/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/roboto/roboto-v30-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/roboto/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto/roboto-v30-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}