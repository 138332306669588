

@keyframes flash {
	0%, 50%, 100% {opacity: 1;}
	25%, 75% {opacity: 0;}
}

.animated.flash {

	animation-name: flash;
}