.timeline {
    margin:0 auto 20px;
    max-width: 1190px;
    position: relative;

    @include media-breakpoint-up(md)  { 
        margin:20px auto 80px;
    }

    &:after {
        @extend %pseudos;
        width: 100%;
        height: 3px;
        background: $primary;
        border-radius:50%;
        left: 0;
        top: 73px;

        @include media-breakpoint-up(md)  { 
            top: 205px;
        }
    }


	&__item {
        width: calc(100% - 160px);
        position:relative;
        height:365px;
        text-align:center;

        @include media-breakpoint-up(md)  { 
            height:420px;
            width: 245px;
        }   


        &:after {
            @extend %pseudos;
            width: 16px;
            height: 16px;
            background: $primary;
            border-radius:50%;
            left:50%;
            margin-left:-8px;
            top: 70px;


            z-index:1;

            @media (min-width:480px) {
                margin-left:-137px;
            }

            @include media-breakpoint-up(md) {
                height:26px;
                left:14px;
                margin-left:0;
                top:194px;
                width:26px;
            }
        }

        &:before {
            @extend %pseudos;
            background:#fff;
            height: 55px;
            border: 3px solid $primary;
            border-radius:50%;
            height: 45px;
            left:50%;
            margin-left:-22px;
            top: 55px;
            width: 45px;

            @media (min-width:480px) {
                margin-left:-151px;
            }

            @include media-breakpoint-up(md)  { 
                height: 55px;
                left:0;
                margin-left:0;
                top: 180px;
                width: 55px;
            }
        }
    }

    &__item-content {
        position:absolute;
        top:135px;
        @extend %h-align;
        max-width: 300px; 
        padding-left:5px;
        text-align:left;
        width: 100%;

        @include media-breakpoint-up(md) {
            top:255px;
        }
    }

    &__item-date {
        margin:0;
        color:#1f1f1f;
        font-weight:bold;
        @include vw-font-size(12px);

        @include media-breakpoint-up(md) {
            @include vw-font-size(18px);
        }
    }

    &__item-excerpt {
        @include vw-font-size(15px);
        margin:0;
        font-weight:bold;

        @include media-breakpoint-up(md) {
            @include vw-font-size(21px);
        }
    
    }

    &__item-text {
        @include vw-font-size(14px);
        margin:0;

        @include media-breakpoint-up(md) {
            @include vw-font-size(16px);
        }
    
    }

    &__item-image {
        width: 140px;
        height: 140px;
        border-radius:50%;
        margin:0 auto;
        position:absolute;
        @extend %h-align;
        top: 0;
        overflow:hidden;


        a {
            position:absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
        }

        img {
            @extend %object-fit;
            margin:0!important;
        }

    }


    &__button {
        position:absolute;
        height:100%;
        width:80px;        
        top: 0;
        z-index: 10;

        span {
            border-radius: 50%;
            display:block;
            width: 50px;
            height:50px;
            border:2px solid $primary;
            margin-top: 50px;
            background: #fff;
            position:relative;
             @extend %h-align;
             cursor:pointer;

            @include media-breakpoint-up(md) { 
                margin-top: 182px;
            }

            &.swiper-button-disabled {
                pointer-events:none;
                border-color:rgba($primary, .2);

                i {
                    opacity:.2;
                }
            }



            i {
                @extend %centered;
                font-size: 15px;
                color: $primary;
            }

            &:hover {
                border-color: $primary;

                i {
                    color:$primary;
                }   
            }
        }

         &--left {
            left:0;

            background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 64%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 64%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 64%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
            i {
                margin-left: -2px;            
            }
        }

        &--right {
            right: 0;
            background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 36%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 36%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

            i {
                margin-right: -2px;            
            }
        }
    }


    @include media-breakpoint-up(md) { 
        &__item:nth-child(odd) {
            .timeline__item-image {
                top: 240px;
            }

            .timeline__item-content {
                top: inherit;
                bottom:260px;
            }
        }
    }
}
