.box-list {
	clear:both;
	margin-left:-15px;
	margin-right:-15px;
	overflow:hidden;
	padding:0;

	&__item {
		display:block;
		padding:10px;
		width:100%;

		@media (min-width:480px) {
			display:inline-block;
			vertical-align:top;
			width:49%;
		}

		@include media-breakpoint-up(md)  {
			width:32.3%;
		}
	}

	&__box {
		border:2px solid #000;
		padding:15px;

		@media (min-width:480px) {
			min-height:130px;
		}

		@include media-breakpoint-up(md)  {
			min-height:155px;
		}

		> span {
			display:block;
			margin-bottom:5px;

			@include media-breakpoint-up(md)  {
				margin-bottom:10px;
			}
		}
	}

	&__detail {
		font-size:13px;
		line-height:28px;
 		overflow: hidden;
 		text-overflow: ellipsis;
		white-space: nowrap;
		max-width:calc(100% - 40px);
		overflow:hidden;
  
		
		@include media-breakpoint-up(md)  {
			font-size:14px;
		}

		span + span {
			&:before {
				content:'|';
				display:inline-block;
				margin:0 7px 0 3px;
				vertical-align:top;
			}
		}
	}

	&__detail--multilang {
		max-width:calc(100% - 110px);
	}

	&__lang {
		font-size:11px;
		padding-left:10px;
		width:65px;

		.select2 {
			width:100%;
		}

		.select2-container--default .select2-selection--single {
			border:none;
			height:28px;

			.select2-selection__arrow {
				height:auto;
				margin-top:-7px;
				right:0;
				top:50%;

				&:after {
					color:#000;
					font-size:11px;
				}
			}

			.select2-selection__rendered {
				height:28px;
				line-height:28px;
				padding-right:0;
			}
		}
	}

	&__download {
		float:Right;
		padding-left:10px;

		.ico-download {
			display:inline-block;
			line-height:28px;
			text-align:right;
			vertical-align:middle;
		}
	}

	&__info {
		
		> div {
			display:inline-block;
			vertical-align:middle;
		}
	}

	.category {
		display:block;
		font-size:14px;

		@include media-breakpoint-up(md)  {
			font-size:18px;
		}
	}

	.name {
		display:block;
		font-size:16px;
		margin:0 0 10px;
		overflow:hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include media-breakpoint-up(md)  {
			font-size:24px;
		}
	}

	.date {

	}

	.size {

	}
}