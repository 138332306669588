body.IE {
	.compat-object-fit {
		background-position:center;
		background-size:cover;
		height:100%;
		left:0;
		position:absolute;
		top:0;
		width:100%;
		z-index:0;

		img { visibility:hidden; }
	}

	.highlight-block__image {
		float: left;
	    height: 510px;
		position:relative;
		width: 50%;
	}

	.tile-item__bg:before,
	.news-item__image-link:before,
	.top-header:before {
		background-image: radial-gradient(circle at 52% 50%, #333333, #000000);
	}
}