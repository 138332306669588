.results {

	&__item {
		border-bottom:1px solid #d8d8d8;
		overflow:hidden;
		padding:15px 0;

		@include media-breakpoint-up(sm) {
			padding:25px 0;
		}
	}

	&__figure {
		display:none;

		@include media-breakpoint-up(sm) {
			display:block;
			float:left;
			margin:0;
			padding-right:20px;
			width:100px;
		}
		
		a {
			display:block;

			&:hover {
				opacity:.85;
			}
		}

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}

	&__info {

		@include media-breakpoint-up(sm) {
			display:block;
			float:left;
			width:calc(100% - 100px);
		}
		
		.title {

		}

		.text {

		}

		.link {

		}
	}
}

.results-products {
	padding-bottom:20px;

	h3 { margin-bottom:0; }
		
	.main-section {
		padding-bottom:0;
		padding-top:0;
	}

	.product-list {
		margin:20px auto;
	}

	.product-list .product-item {
		margin-top:25px;
	}
}