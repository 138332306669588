.order-list {
    counter-reset: item;
    margin:15px auto;
    list-style: none;
    padding:0;

	&__item {
		counter-increment: item;
		margin-bottom:5px;

		@include media-breakpoint-up(sm) {
			margin-bottom:15px;
		}

		&:before {
			@include vw-font-size(12px);
			background:#000;
			color:$white;
			content: counter(item);
			display: inline-block;
			margin-right: 10px;
			line-height:25px;
			height:25px;
			text-align: center;
			vertical-align:top;
			width:25px;

			@include media-breakpoint-up(sm) {
				@include vw-font-size(15px);
				line-height:32px;
				height:32px;
				width: 32px;
			}
		}

		p {
			display:inline-block;
			margin-top:0;
			padding-left:5px;
			width:calc(100% - 40px);
			vertical-align:top;

			@include media-breakpoint-up(sm) {
				width:calc(100% - 50px);
			}
		}
	}
}