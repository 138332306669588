// ==========================================================================
// Footer
// ==========================================================================

// 1.Config


// 2.Base

.gs18-Footer {
	
}

.scroll-to-top {
    bottom: -50px;
    margin: 0;
    position: fixed;
    right: 0;	 
    transition:$transition-base;   
    z-index: 1;

    &.unpinned {
    	bottom:0;
    }

	> * {
	    background-color: #000;
	    color: #fff;
	    display: block;
	    height: 48px;
	    position: relative;
	    width: 48px;

	    &:before {
	    	border-top: 2px solid #fff;
		    border-left: 2px solid #fff;
	    	content: "";
		    height: 8px;
		    left: 50%;
		    position: absolute;
		    -webkit-transform: translate(-50%,-50%) rotate(45deg);
		    transform: translate(-50%,-50%) rotate(45deg);
		    top: 55%;
		    width: 8px;			    
	    }
	}
}

.scroll-to-top.gs18-Footer { display: none !important; }

.gs18-Footer__Links {
	@media only screen and (max-width: 980px) {
		margin-top:0;
	}
}

.gs18-Footer__Inner {
	@media only screen and (min-width: 981px) {
		height:91px;
	}
}