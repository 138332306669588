// ==========================================================================
// Tile Item
// ==========================================================================

// 1.Config


// 2.Base

.home-news {
    @extend %clearfix;
    display:flex;
    flex-wrap:wrap;

    .news-item {
        background-color:$white;
        width:100%;

        @include media-breakpoint-up(md) {
            width:33.3%;
        }

        @media (min-width:480px) {
            width:50%;
        }

        @media (min-width:768px) {
            width:33.333%;
        }
    }
}

.news-item {
    $block: '.news-item';
    position:relative;
    color:#fff;
    background-color:rgba(233, 233, 233, 0.5);
    @extend %clearfix;

    &__image {
		width:100%;
		position:relative;
        overflow:hidden;

		img {
            display:block;
            height:auto;
            width:100%; 
        }
    }

    &__image-bg {
    	height:auto;
        transition:$transition-base;
        width:100%;
    }

    &__image-link {

        &:before {
            background-image: radial-gradient(circle at 52% 50%, #ffffff, #000000);
            content:'';
            display:block;
            height:100%;
            left:0;
            mix-blend-mode: multiply;
            opacity:0;
            position:absolute;
            top:0;
            transition:$transition-base;
            width:100%;
            z-index:1;
        }

        
        &--video:after {
            @extend %pseudos;
            width:70px;
            height:70px;
            background-size:cover;
            background-image:url(../svg/play-video.svg);
            @extend %centered;

        }

        &:hover {

            &:before { opacity:.4; }
    
            #{$block}__image-bg { 
                transform:scale(1.1);
                opacity:0.97;

            }
        }
    }


    &__content {
		padding:20px 15px;

		@include media-breakpoint-up(md)  {
			padding:20px 15px;
		}

        @include media-breakpoint-up(lg)  {
            padding:20px;
        }

        @include media-breakpoint-up(xl)  {
            padding:30px;
        }
    }

    &__title {
    	margin:0;
        color:#000;
        display:block;
        margin:0 0 5px;
    }

    &__cta {
        a {
            opacity:.5;
            text-decoration:underline;

            &:hover { opacity:1; }
        }
    }

    &:nth-child(1) {
        #{$block}__image { 
            //background:aqua;
        }
    }

    &:nth-child(2) {
        #{$block}__image { 
            //background:red;
        }
    }

    &:nth-child(3) {
        #{$block}__image { 
            //background:pink;
        }
    }


    /*@include media-breakpoint-up(md)  {
        height:185px; 
        


        #{$block}__image { 
            width:40%;
            float:left;
            height:185px; 
        }

        #{$block}__content{ 
            width:46%;
            float:left;
            height:185px; 
            position:relative;
        }

        #{$block}__content-inner{ 
            position:absolute;
            bottom:30px;
            width:calc(100% - 60px);
        }

        &:nth-child(2) {
      

            #{$block}__image{
                float:right;
            }
        }

        &:nth-child(3) {
          background-color:#e0e0e0;
        }
    }*/

    /*@include media-breakpoint-up(lg)  {
        width:50%;
        float:left;

        height:285px; 
        


        #{$block}__image { 
            height:285px; 
            width:60%;
        }

        #{$block}__content{ 
            height:285px; 
            width:40%;
        }

        






        &:nth-child(1) {

            float:right;
            height:570px;

            #{$block}__image{
                height:400px;
                width:100%;
                float:none;
            }

            #{$block}__content{
                width:100%;
                float:none;
                height:170px;

            }
        }
    }
    */




   
}