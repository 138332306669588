// ==========================================================================
// Distributors Item
// ==========================================================================

// 1.Config


// 2.Base


.distributor-item {

    &__title {
        margin:0;
        font-size:18px;
        font-weight:bold;
        margin-bottom:10px;

        @include media-breakpoint-up(md) {
            font-size:24px;
        }
    }

    &__adress {
        margin:0;
        line-height:1.4;

        span {
            display:block;
        }
    }

    &__info {
        margin:20px 0;
        @extend %reset-list;
        line-height:1.4;

        i {
            color:#000;
            margin-right:5px;
        }

    }

    &__cta {
        a {
            display:inline-block;
        }
    }


}