// ==========================================================================
// Cookies Bar
// ==========================================================================

// 1.Config
$cookies-bg:$white;
$cookies-border-color:#ccc;
$cookies-button-bg:#000;
$cookies-button-bg-hover:#f50f10;

// 2.Base

.cookies-bar {
    @extend %clearfix;
    background:$cookies-bg;
    bottom:0;
    display:none;
    left:0;
    overflow-y:auto;
    padding:10px 15px;
    position:fixed;
    text-align:center;
    top:0;
    width:100%;
    z-index:998;    
  
    @include media-breakpoint-up(md) {
        text-align:left;
    }

 
    .container {
        margin:35px auto;
        max-width:960px;
        text-align:center;

        @media (min-width:768px) and (min-height:700px) {
            padding:0 20px;
            position: relative;
            top: calc(50% - 47px);
            transform: translate(0, -50%);
        }

        p {
            text-align:left;

            a {
                &:before {
                    background:url(../svg/external.svg) center no-repeat;
                    background-size:contain;
                    content:'';
                    display:inline-block;
                    height:26px;
                    margin:0 5px 0 0;
                    vertical-align:top;
                    width:26px;
                }

                &:hover {
                    &:before {
                        background:url(../svg/external-red.svg) center no-repeat;
                        background-size:contain;
                    }
                }
            }
        }

        .btn {
            margin-top:20px;
        }
    }

    &__logo {
        margin:0 auto;
        max-width:1380px;
        text-align:left;

        @include media-breakpoint-up(md) {
            margin-top:9px;
        }

        > * {
            display:inline-block;
            vertical-align:middle;
        }

        a {
            @include media-breakpoint-up(md) {
                border-right:1px solid #e1e1e1;
                padding:0 16px;
            }
        }

        img {
            display:block;
            height:auto;
            width:84px;
        }

        p {
            font-size: 14px;
            font-weight: 700;
            margin-left: 12px;
            margin-right: 10px;
        }
    }
}