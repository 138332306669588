.butllet-list {
	margin:15px auto;
	padding-left:60px;

	@include media-breakpoint-up(sm) {
		padding-left:70px;
	}

	&__item {
		list-style-type:disc;
		margin-bottom:15px;
	}
}