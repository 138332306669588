// ==========================================================================
// News Extended
// ==========================================================================

// 1.Config


// 2.Base

.news-extended {
    
    &__container {
    	padding:0 15px;
	    margin:35px auto;
	    max-width:905px;

	    @include media-breakpoint-up(md)  {
	        padding:0 30px;
	    }

	    &--extra {
	    	max-width:1460px;
	    }
    }

    
    &__top {
       p {
            @include vw-font-size(16px);
            font-weight:400;
            line-height: 1.39;
            margin:0;

            @include media-breakpoint-up(md)  {
                @include vw-font-size(18px);
            }

       }

       span {
            @include vw-font-size(18px);
            font-weight: bold;

            @include media-breakpoint-up(md)  {
               @include vw-font-size(24px);            
            }

            &:after {
                content:'|';
                padding:0 5px 0 8px;

            }
       }
    }

    &__title {
        @include vw-font-size(26px);
        margin:0;
        padding: 15px 0;
        font-weight:bold; 

        @include media-breakpoint-up(md)  {
            @include vw-font-size(45px);
        }
    }

    &__highlight {
        display:block;
        font-weight:300 !important;
        margin-top:0;
    }

    &__image {
        img {
            width:100%;
        }
    }

     &__subtitle {
        @include vw-font-size(18px);
        font-weight:bold; 
        margin:0 0 10px;

        @include media-breakpoint-up(md)  {
            @include vw-font-size(32px);
        }
    }


    &__description {
        p {
            @include vw-font-size(16px);
            line-height:1.4;

            @include media-breakpoint-up(md)  {
                @include vw-font-size(20px);
            }
        }

        figure {
            display:block;
            margin:20px auto;
            text-align:center;

            @include media-breakpoint-up(md)  {
                margin:40px auto;
            }

            img {
                margin:0 auto;
                height:auto;
                width:auto;
                max-height:100%;
                max-width:100%;
            }
        }

        a { text-decoration:underline; }
    }


    &__links {
        margin-top:50px;   
    }

    &__links-list {
        @extend %reset-list;
        @include vw-font-size(18px);

        li {
            font-weight:normal;

            a {
                text-decoration:underline;

                &:hover {
                    color:$primary;
                }
            }
        }

    }

    &__files {
        margin-top:50px;     
    }

    &__files-list {
        @extend %reset-list;
        @include vw-font-size(18px);
    

        li {
            display:block;
            padding: 15px 0;
            border-bottom: solid 1px #d8d8d8;
            position:relative;

            a {
                display:block;
                font-weight:bold;
                position:relative;
                padding-right:40px;

                &:hover {
                    color:$primary;
                }

                &:after {
                    @include vw-font-size(20px);
                    font-family: "mitsubishi-font";
                    content: '\e805';
                    font-weight:normal; 
                    position:absolute;
                    right:5px;
                    @extend %v-align;
                }
            }
        }
    }

    .share {
        display:block;
        padding:0;
        text-align:right;
    }

    div.inline {
        overflow:hidden;

        > * {
            display:block;
            float:left;
        }

        figure { margin:0; }
    }

}