.photoprintme-family {
	margin:25px auto;
	max-width:740px;
	padding:0 15px;

	@include media-breakpoint-up(md)  {
		margin:50px auto 0;
	}

	&__logo {
		display:block;
		height:auto;
		margin:0 auto 20px;
		max-width:462px;
		width:100%;

		img {
			display:block;
			height:auto;
			width:100%;
		}
	}

	&__highlight {
		@include vw-font-size(18px);
		display:block;
	    font-weight:bold;
	    margin:0 0 20px;
	    text-align:center;

	    @include media-breakpoint-up(md)  {
	        @include vw-font-size(32px);
	        margin-bottom:36px;
	    }
	}

	&__video {
		display:block;
		margin:0 auto 30px;
		max-width:580px;
		position:relative;
		width:90%;

		@include media-breakpoint-up(md)  {
			margin-bottom:60px;
		}

		&:before, &:after {
			@include vw-font-size(106px);
			display:block;
			color:$white;
			left:50%;
			margin:0 auto;
			position:absolute;
			top:50%;
			transform:translate(-50%, -50%);
			width:auto;
			z-index:1;
		}

		&:after {
			background:rgba(219, 0, 0, 0.4);
			border-radius:50%;
			content:'';
			display:block;
			height:134px;
			transition:$transition-base;
			width:134px;
			z-index:0;
		}

		&:hover {
			&:after {
				background:rgba(219, 0, 0, .6);
			}
		}
	}

	&__image {
		display:block;
		margin:0 auto;
		width:100%;
	}

	.text-big {

	}
}