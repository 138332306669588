// ==========================================================================
// Section
// ==========================================================================

// 1.Config




// 2.Base

.section {
	@extend %clearfix;
}

section.history {
	border-top:1px solid #000;
	margin-top:20px;
	padding-top:15px;

	@include media-breakpoint-up(md)  { 
		margin-top:70px;
		padding-top:20px;
	}

	.subtitle {
		@include vw-font-size(18px);
		@include vw-line-height(24px);
		display:block;
		text-align:center;

		@include media-breakpoint-up(md) {
			@include vw-font-size(32px);
			@include vw-line-height(38px);
		}
	}
}