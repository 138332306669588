// ==========================================================================
// Tile Item
// ==========================================================================

// 1.Config


// 2.Base

.tile-item {
    position:relative;
    color:#fff;
    height:300px;
    margin-bottom:2px; 

    &:hover {

        .tile-item__bg:before { opacity:0; }
        
        .tile-item__cta {
            .cta {
                background:url(../images/mitsubishi/icon/ArrowNext-R.svg) no-repeat right 7.5px center #fff;
                color:#DB0000;
            }
        }
    }

    @include media-breakpoint-up(md)  {
        height:350px; 
    }

    .full-link {
        display:block;
        height:100%;
        left:0;
        top:0;
        position:absolute;
        width:100%;
        z-index:3;
    }

    &--banner {
        height:300px; 

         @include media-breakpoint-up(md)  {
            height:315px; 
        }
    }


    &__content {
        text-align:center;
        position:relative;
        z-index:3;
        margin:0 auto;
        padding:0 20px;       
        top: 50%;
        transform: translateY(-50%);       
    }

    &__title {
        font-weight: 700;
        line-height:1.1;
        margin:0 auto 10px;
        max-width:900px;
        //text-shadow:1px 0px 10px rgba(0, 0, 0, 0.1);

        &--sizeM {
         
        }
    }


    &__text {
        display:none;
        max-width:600px;
        margin:0 auto;
        //text-shadow:1px 0px 10px rgba(0, 0, 0, 0.1);

        @include media-breakpoint-up(md)  {
            display:block;
        }

        p {
          margin:0;
        }
    }

    &__cta {
        .gs18-ButtonLink {
            background-color:#fff;
        }
    }



    &__bg {
        z-index:-1;

        &:before {
            //background-image: radial-gradient(circle at 52% 50%, #ffffff, #000000);
            background:rgba(0,0,0,.5);
            content:'';
            display:block;
            height:100%;
            left:0;
            mix-blend-mode: multiply;
            opacity:.4;
            position:absolute;
            top:0;
            transition:$transition-base;
            width:100%;
            z-index:1;
        }

        img {
            position:absolute;
            width:100%; 
            height:100%;
            object-fit: cover;
            top: 0;     
            left: 0;
        }

    }

   
}