// ==========================================================================
// Select2
// ==========================================================================

.select-os + .select2 {

}


.select2-container {
	//width: 100%!important;

}

.select2-search {
	display:none;
}

.select2-container--default .select2-selection--single {
	border-radius:0;
	height: 40px;
	background-color:#fff;
	border:2px solid #d8d8d8;
	font-size:16px;

	&:focus {
        outline: 0;
        box-shadow: none; 
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	text-align: left;
	padding:0 20px 0 10px;
	line-height: 40px;
	//font-weight: bold;  
	color:#5b5b5f;
	font-size:16px; 
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 10px;
	right:8px;
	font-size:16px;

	&:after {
		display:block;
		font-family: "mitsubishi-font";
        content: '\e804';
        color: #d8d8d8;
	}
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow {
	transform:scale(1,-1);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	display:none;	
}

.select2-dropdown {
	border-radius: 0;
	border-color:#d8d8d8;
}

.select2-results__option {
	padding:9px 10px;
	color:#5b5b5f;
	font-size:16px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	color:$primary;
	background-color: #fff;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
	background-color: #efefef;

}



.form-group--error .select2-container--default .select2-selection--single {

}


.select2-container--default .select2-selection--single .select2-selection__placeholder {
	color:#5b5b5f;
	font-weight: bold;

}
	
