.comparator {
	background:#f6f6f6;
	bottom:0;
	height:400px;
	left:0;
	position:fixed;
	right:0;
	transition:$transition-base;
	z-index:1;

	@include media-breakpoint-up(sm) {
		height:275px;
	}

	@include media-breakpoint-up(md) {
		height:265px;
	}

	@include media-breakpoint-up(lg) {
		height:320px;
	}

	&__box {
		margin:0 auto;
		max-width:760px;
		padding:20px 10px;
    
        @media (min-width:1125px) {
            max-width:1125px;
        }
	}

	&__close {
		display:block;
		font-size:18px;
		left:0;
		position:absolute;
		padding:0 5px;
		text-align:right;
		top:15px;
		width:100%;

		@include media-breakpoint-up(sm) {
			font-size:24px;
			padding:0 15px;
		}

		.ico-maximize { display:none; }
		.ico-minimize { display:block; }
	}	

	&__title {
		display:block;
		margin-bottom:0;
		padding:0 7px;

		@include media-breakpoint-up(sm) {
			padding:0 15px;
		}
	}

	&__list {
		margin:10px auto 0;
		overflow:hidden;
		padding:0;
		text-align:center;

		@include media-breakpoint-up(sm) {
			margin-top:15px;
		}
	}

	&__product {
		display:block;
		float:left;
		margin-bottom:10px;
		padding:0 7px;
		width:50%;

		@include media-breakpoint-up(sm)  {
			margin-bottom:0;
			padding:0 15px;
			width:25%;
		}
	}

	&__figure {
		border:1px solid #e9e9e9;
		display:block;
		margin-bottom:10px;
		position:relative;

		&:before {
			background:#e9e9e9;
			content:'';
			display:block;
			height:100px;

			@include media-breakpoint-up(sm) {
				height:120px;
			}

			@include media-breakpoint-up(md)  {
				height:auto;
				padding-top:59%;
			}
		}
	}

	&__delete {
		background-color:#fff;
		background-position:center;
		background-repeat:no-repeat;
		background-size:contain;
		display:block;
		height:100%;
		left:0;
		position:absolute;
		top:0;
		width:100%;

		&.ico-close:after {
			background:#fff;
			content:'';
			display:block;
			height:100%;
			left:0;
			opacity:0;
			position:absolute;
			top:0;
			transition:$transition-base;
			width:100%;
		}

		&.ico-close:before {
			background:transparent;
			font-size:13px;
			position:absolute;
			right:5px;
			top:7px;
		}

		&:hover {
			&:after { opacity:.4; }
		}
	}

	&__product__name {
		display:block;
		font-weight:500;
		min-height:25px;
	}

	&__actions {
		padding:0 7px;
		text-align:right;

		@include media-breakpoint-up(sm)  {
			padding:0 15px;
		}

		a + a {
			margin-left:10px;
		}

		.cta {
			margin-top:0;

			@include media-breakpoint-up(md)  {
				margin-top:10px;
			}
		}

		a {
			@include vw-font-size(14px);

			@include media-breakpoint-up(md)  {
				@include vw-font-size(16px);
			}
		}
	}
}

.comparator--minify {
	height:55px;

	.comparator__close {
		.ico-maximize { display:block; }
		.ico-minimize { display:none; }
	}
}