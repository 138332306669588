// ==========================================================================
// Highlight
// ==========================================================================

// 1.Config


// 2.Base
.highlight {
	@include vw-font-size(18px);
    margin-top:0;

    @include media-breakpoint-up(md)  {
        @include vw-font-size(18px);
    }

    @include media-breakpoint-up(lg)  {
        @include vw-font-size(22px);
    }

    @include media-breakpoint-up(xl)  {
        @include vw-font-size(24px);
    }
}