// ==========================================================================
// Title
// ==========================================================================

// 1.Config


// 2.Base
.title {
	@include vw-font-size(32px);
    font-weight: 700;
    //line-height:1.4;
    line-height:1.1;
    margin:0 0 20px;

    @include media-breakpoint-up(md)  {
        @include vw-font-size(60px);
    }
}

.title--medium {
	@include vw-font-size(26px);
    font-weight: 700;
    line-height:1.4;
    margin:0 0 20px;

	@include media-breakpoint-up(md)  {
        @include vw-font-size(50px);
    }	
}



.title--small {
	@include vw-font-size(18px);
    font-weight: bold;
    line-height: 1.4;

    @include media-breakpoint-up(md)  {
        @include vw-font-size(25px);
    }
}

.title--center {
	margin:0 auto 20px;
}


/*.title--block {
    @include vw-font-size(20px);
    margin:0;
    padding: 15px 0;

    @include media-breakpoint-up(md)  {
        padding: 27px 0;
        @include vw-font-size(px);
    }

    &--centered {
        text-align:center;
    }
}*/