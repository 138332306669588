// ==========================================================================
// Home Grid
// ==========================================================================


.home-grid {
	@extend %clearfix;

	.tile-item {
		height:300px; 


		@include media-breakpoint-up(md) {
			width:50%;
			float:left; 
			height:400px; 
			margin:0;
		}
	}


	.tile-item:nth-child(1){
		height:450px; 

		 @include media-breakpoint-up(md)  {
			height:800px;
		}
	}

	.tile-item:nth-child(4){
		height:300px;
		width:100%;

	    @include media-breakpoint-up(md)  {
	        height:350px; 
	    }
	    .title { margin:0 auto; }
	}

}








