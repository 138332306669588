.pagination {
	display:block;
	margin:20px auto;
	padding:0 15px;
	text-align:center;

	&__item {
		@include vw-font-size(15px);
		background:#d8d8d8;
		color:#000;
		display:inline-block;
		line-height:30px;
		text-align:center;
		width:30px;

		@include media-breakpoint-up(md)  {
			@include vw-font-size(18px);
			line-height:40px;
			width:40px;
		}
	}

	&__link {
		background:#e9e9e9;
		color:#afafaf;
		display:block;
		line-height:30px;
		transition:$transition-base;

		@include media-breakpoint-up(md)  {
			line-height:40px;
		}

		&:hover {
			background:#d8d8d8;
			color:#000;
		}
	}
}