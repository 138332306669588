$borderFormColor: #d8d8d8;
$borderErrorFormColor: #DB0000;
$borderFocusFormColor: #000;

.form {
	margin-bottom:30px;

    input[name="mail"] {
        display: none;
    }

	.form-group {
		@include vw-font-size(16px);
		clear:both;
		display:block;
		margin-bottom:20px;
		//overflow:hidden;
		padding:0 15px;
		position:relative;
		width:100%;


		@include media-breakpoint-up(sm) {
			margin-bottom:35px;
		}

		.select2 {
			display:block;
			min-width:150px;
			width:100% !important;
			
			&.error {
				.select2-selection--single {
					border-color:$borderErrorFormColor;
				}
			}
		}

		.select2-container--default .select2-selection--single {
			border:0;
			border-bottom:2px solid $borderFormColor;
		}

		&.error,
		&.form-group--error {
	    	.select2-container--default .select2-selection--single {
	    		border-color:$borderErrorFormColor;
	    	}

	    	input[type="checkbox"] + .form-label--checkbox .form-control--checkbox {
	    		border-color:$borderErrorFormColor;	
	    	}

	        .g-recaptcha > div, .form-control--checkbox {
	            border:1px solid $borderErrorFormColor;
	        }
	    }
	}

	.form-group--center {
		text-align:center;
	}

	.form-group--half {
		display:block;

		@include media-breakpoint-up(sm) {
			clear:none;
			float:left;
			width:50%;
		}
	}

	.form-group--inline {
		display:inline-block;
		width:auto;
	}

	.form-group--left {
		margin-bottom:20px;
		padding:0;
		width:100%;

		@include media-breakpoint-up(md) {
			float:none;
			margin-bottom:0;
			padding:0 15px 0 0;
			width:auto;

			&:first-child {
				padding-left:0;
			}
		}
	}

	.form-group--right {
		padding:0;

		@include media-breakpoint-up(sm) {
			float:right;
			margin:0 auto 35px;
		}

		@include media-breakpoint-up(md) {
			margin-top:0;
			padding:0 15px 0 0;
			width:auto;
		}
	}


	.form-control {
		@include vw-font-size(16px);
		border:0;
		border-bottom:solid 2px $borderFormColor;
		border-radius:0;
		color: #5b5b5f;
		height:40px;
		font-weight:300;
		line-height:38px;
    	padding:0 10px;
    	width:100%;

    	&:focus {
    		outline:0;
    		border-color:$borderFocusFormColor;
    	}


    	&.error {
    		border-color:$borderErrorFormColor;
    	}
	}

	.form-group--error {
		.form-control {border-color:$borderErrorFormColor;}
	}

	.form-control--picker {
		font-family:"Roboto",sans-serif;
		line-height:36px;
	}


	.form-control--textarea {
		font-family:"Roboto",sans-serif;
		height:90px;
		resize:none;

		@include media-breakpoint-up(sm) {
			height:120px;
		}
	}

	.form-control-ico-search {
		border:0;
		cursor:pointer;
		padding:0;
		vertical-align:middle;

		.form-control {
			background:url(#{$url-statics}/#{$image-path}/mitsubishi/icon/Search.svg) center right 5px no-repeat;
			background-size:24px;
			padding-right:35px;
		}
	}

	input[type="checkbox"], input[type="radio"] {
		left:0;
		opacity:0;
		position:absolute;
		top:0;
	}

	input:checked + label .form-control--checkbox:before,
	input:checked + label .form-control--radio:before {
		opacity:1;
	}

	.form-control--checkbox {
		border:2px solid $borderFormColor;
		display:inline-block;
		height:20px;
		margin-right:7px;
		position:relative;
		width:20px;
		vertical-align:top;

		&:before {
			@include vw-font-size(10px);
			left:50%;
			margin:0 auto;
			opacity:0;
			position:absolute;
			top:50%;
			transition:$transition-base;
			transform:translate(-50%, -50%);
		}

		&.error {
			border-color:$borderErrorFormColor;
		}
	}

	.form-label-text {
		display: inline-block;
		margin-top: 0;	    
	    vertical-align: top;
	    width: calc(100% - 35px);
	}

	.form-label--checkbox {
		@include vw-font-size(16px);
		cursor:pointer;

		a {
			text-decoration:underline;

			&:hover { opacity:.85; }
		}

		@include media-breakpoint-up(sm) {
			//@include vw-font-size(18px);
		}
	}

	.input-message--error,
	.error-msg {
		@include vw-font-size(14px);
		background:rgba($borderErrorFormColor,.25);
		display:none;
		font-weight:300;
		margin:0 auto 20px;
		padding:10px 15px;
		text-align:left;
	}

	.input-message--error {
		margin-bottom: 10px;
		padding:5px 10px;

		@include media-breakpoint-up(sm) {
			bottom:-29px;
			left:15px;
			margin-bottom: 0;
			padding:5px 10px;
			position:absolute;
			right:15px;
		}
	}

	.form-group--textarea {
		
		.input-message--error {
			margin-top:-6px;

			@include media-breakpoint-up(sm) {
				bottom:-23px;
				margin-top:0;
			}
		}		
	}

	.form-group--error .input-message--error {
		display: block;
	}

	small {
		@include vw-font-size(14px);
		@include vw-line-height(20px);
		display:block;
	}

	.btn {
		-webkit-appearance: none;
		-webkit-border-radius:0px;
		border-radius: 0;
		cursor:pointer;
	}

	.no-padding { padding:0; }
}

.warning-message {
	background:rgba($primary,.1);
	font-weight:300;
	margin:0 auto 20px;
	padding:10px 15px;
	text-align:left;

	a {
		color:$primary;
		text-decoration:underline;
	}
}
