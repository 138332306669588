/* Welcome to The RSB SASS Bloilerplate
 * BEM + SASS + SMACSS starter 
 *
 * This is a SASS starter structure, with the focus in use
 * the SMACSS archtecture and BEM class naming rules. 
 *
 *
 */


/* ---------------------------------
 * Config
  ----------------------------------*/

@import "utilities/config"; 

/* ---------------------------------
 * Utils
 ----------------------------------*/
@import "utilities/utils";
@import "utilities/custom-placeholders";
@import "utilities/reset";

/* ---------------------------------
 * Vendors
 ----------------------------------*/
@import "vendors/swiper";  
@import "vendors/fancybox";
@import "vendors/select2"; 
@import "vendors/datepicker";
@import "vendors/customscrollbar";

/* ---------------------------------
 * Vendors Extensions
 ----------------------------------*/
@import "vendors-extensions/select2"; 


/* ---------------------------------
 * Base
 ----------------------------------*/
@import "base/fonts";  

/* ---------------------------------
 * Components
 ----------------------------------*/
@import "components/main-slide";
@import "components/top-header";
//@import "components/title-block";
@import "components/tile-item";
@import "components/news-item";
@import "components/news-box";
@import "components/product-grid";
@import "components/product-item";
@import "components/product-extended";
@import "components/product-gallery";
@import "components/product-actions";
@import "components/product-accordeon";
@import "components/product-slide";
@import "components/product-table";
@import "components/product-components";
@import "components/buttons";
@import "components/share";
@import "components/highlight-block";
@import "components/timeline";
@import "components/news-extended";
@import "components/distributors";
@import "components/distributors-item";
@import "components/form";
@import "components/maps";
@import "components/main-section";
@import "components/notification";
@import "components/article";
@import "components/content-fancy";
@import "components/navigation";
@import "components/title";
@import "components/text";
@import "components/highlight";
@import "components/cta";
@import "components/animate";
//@import "components/preloader";
@import "components/ie";


@import "components/cookies-bar";
//@import "components/select2";
   

/* ---------------------------------
 * Layout
 ----------------------------------*/
//@import "layout/header";
//@import "layout/navbar";
//@import "layout/menu";
//@import "layout/main";
@import "layout/footer";
@import "layout/section";
@import "layout/gs18-custom";
@import "layout/base";
@import "layout/error";


/* ---------------------------------
 * Modules
 ----------------------------------*/
 
@import "modules/home-grid";
@import "modules/pagination";
@import "modules/order-list";
@import "modules/butllet-list";
@import "modules/download-table";
@import "modules/box-list";
@import "modules/results";

@import "modules/comparator";
@import "modules/product-comparator";
@import "modules/table-comparator";
@import "modules/photoprintme-family";
@import "modules/media-supply-policy";

/* ---------------------------------
 * Pages
 ----------------------------------*/

template { display:none; }


.config-cookies-cta {
  align-items:center;
  background-color: #000;
  border:0;
  bottom: 50px;
  color:$white;
  cursor:pointer;
  display: flex;
  height: 48px;
  justify-content:center;
  margin: 0;
  padding:0 14px;
  position: fixed;
  right: 0;	 
  transition:$transition-base;   
  //width: 48px;
  z-index: 1;

  &:hover span {
    display:block;
  }

  span {
    display:none;
    margin-left:20px;
  }

  svg {
    display:block;
    width:20px;

    path {
      fill:#fff;
    }
  }
}

.help--icon,
.help--icon + * {
	vertical-align: middle;
	margin-right: 5px;
}

.form {
	.form-group--help {
		position: relative;

		.form-control {
			padding-right: 40px;
		}

		.help--icon--link {
			display: block;
			width: 26px;
			height: 26px;
			position: absolute;
			right: 15px;
			margin-block: auto;
			top: 0;
			bottom: 0;
			z-index: 100;
			opacity: 0.2;
		}
	}
}
