// ==========================================================================
// Product Item
// ==========================================================================

// 1.Config


// 2.Base

.product-list {
    margin:0 auto 60px;
    max-width:760px;
    
    @media (min-width:1125px) {
        max-width:1436px;
    }

    @include media-breakpoint-up(md)  {
        margin-bottom:60px;
        text-align:left;
    }
    /*margin:0 auto 60px;
    max-width:1110px;
    overflow:hidden;*/

    &.product-list--medium {
        @media (min-width:1125px) {
            max-width:1125px;
        }
    }

    &.media, &.media-medical {
        .product-item__description { display:none; }
    }

    &.kiosks-retail {
        .btn-compare { display:none; }
    }
}



.product-category-list { background:#F5F5F5; }

.product-item {
    display:block;
    max-width:300px;
    margin:0 auto 30px;
    padding:0 15px;
    text-align:center;
    vertical-align:top;
    width:100%;    

    @include media-breakpoint-up(md)  {
        display:inline-block;
        margin:60px 30px 0;
    }

    /*@include media-breakpoint-up(sm)  {
        float:left;
        padding:60px 30px 0;
        width:50%;
    }

    @include media-breakpoint-up(lg)  {
        width:33.3%;
    }*/

    
    &__image {
        background:#efefef;
        margin-bottom:10px;
        max-width:260px;

        @include media-breakpoint-up(lg)  {
            margin-bottom:25px;
        }
    }

    &__image--default {
        max-height:170px;
        max-width:240px;
    }

    &__info {

    }


    &__title {
        line-height: 1.07;
        margin:0 0 9px;
    }

    &__description {
        margin:0;
        font-style: normal;
        font-weight:400;
        line-height: 1.33;
        height:46px;
        overflow:hidden;

        @include media-breakpoint-up(md)  {
            height:48px;
        }
    }

    &__cta {

    }

    .btn-compare {
        display:inline-block;
        font-weight:300;
        margin-top:5px;
    }
}