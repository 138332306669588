.calendar-content {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  white-space: normal;
  width: 210px;
  height: 230px;
  background-color: white;
}
.calendar-content.calendar_days > .calendar-days {
  display: block;
}
.calendar-content.calendar_months .calendar-months {
  display: block;
}
.calendar-content.calendar_years .calendar-years {
  display: block;
}
.calendar-days,
.calendar-months,
.calendar-years,
.calendar-buttons {
  display: none;
}
.calendar-row,
.calendar-head {
  display: table;
  width: 100%;
}
.calendar-row > span,
.calendar-head > span {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.calendar-header {
  display: table;
  width: 100%;
  height: 15%;
}
.calendar-header > div {
  display: table-cell;
  height: 100%;
  text-align: center;
  position:relative;
  vertical-align: middle;
  cursor: pointer;
}
.calendar-prev,
.calendar-next {
  width: 20%;
}
.calendar-caption {
  width: 60%;
}
.calendar-days,
.calendar-months,
.calendar-years {
  height: 85%;
}
.calendar-head {
  height: 13%;
}
.calendar-head span {
  cursor: default;
}
.calendar-body {
  height: 87%;
}
.calendar-body .calendar-row {
  height: 16.66666667%;
}
.calendar-body span {
  width: 14.28%;
  height: 100%;
  cursor: pointer;
}
.calendar-body span.calendar_otherMonth,
.calendar-body span.calendar_untouchable {
  cursor: default;
}
.calendar-months .calendar-row,
.calendar-years .calendar-row {
  height: 25%;
}
.calendar-months span,
.calendar-years span {
  height: 100%;
  width: 33.3%;
  cursor: pointer;
}
.calendar-months span.calendar_untouchable,
.calendar-years span.calendar_untouchable {
  cursor: default;
}
.calendar_hide {
  display: none !important;
}
.calendar_show {
  display: block !important;
}
.calendar-wrap {
  white-space: nowrap;
  display: none;
  position: absolute;
  z-index:1;
}
.calendar-wrap,
.calendar-wrap *:focus {
  outline: none;
}
.calendar-wrap * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.calendar-wrap.calendar_isMobile {
  z-index: 99999;
  position: fixed;
  width: 80%;
  height: 80%;
  line-height: 1;
}
.calendar-wrap.calendar_isMobile .calendar-title {
  width: 100%;
  text-align: center;
  position: absolute;
  top: -1.5em;
  left: 0;
}
.calendar-wrap.calendar_isMobile .calendar-content {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.calendar-wrap.calendar_isMobile .calendar-content.calendar_show {
  display: block;
}
.calendar-wrap.calendar_isMobile .calendar-header {
  height: 11%;
}
.calendar-wrap.calendar_isMobile .calendar-days,
.calendar-wrap.calendar_isMobile .calendar-months,
.calendar-wrap.calendar_isMobile .calendar-years {
  height: 78%;
}
.calendar-wrap.calendar_isMobile .calendar-buttons {
  display: table;
  height: 11%;
  width: 100%;
}
.calendar-wrap.calendar_isMobile .calendar-buttons > div {
  display: table-cell;
  width: 50%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.calendar-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 9999;
}
.calendar-input {
  border: 1px solid green;
}
.calendar-icon {
	background-color: gray;
	border: 1px solid green;
	display:none;
}
.calendar_active .calendar-input {
  border: 1px solid red;
}
.calendar_active .calendar-icon {
  border: 1px solid red;
}
.calendar-content {
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 0;
  color: #777777;
  font-family:"Roboto",sans-serif;
  font-size:13px;
  margin-top:1px;
}
.calendar-content span {
  border: 1px dashed transparent;
}
.calendar-content span.calendar_active {
  background-color: $secondary !important;
  color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.15);
}
.calendar-content span.calendar_otherMonth,
.calendar-content span.calendar_untouchable {
  color: #c8c8c8;
  background-color: inherit;
}
.calendar-content span.calendar_otherMonth:hover,
.calendar-content span.calendar_untouchable:hover,
.calendar-content span.calendar_otherMonth:active,
.calendar-content span.calendar_untouchable:active,
.calendar-content span.calendar_otherMonth.calendar_active,
.calendar-content span.calendar_untouchable.calendar_active {
  background-color: inherit;
  color: #c8c8c8;
}
.calendar-content span.calendar_inRange {
  background-color: #e0f4fb;
}
.calendar-content span.calendar_inRange:hover {
  background-color: inherit;
}
.calendar-content span.calendar_otherMonth.calendar_inRange:hover {
  background-color: #e0f4fb;
}
.calendar-content span.calendar_focus {
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: $secondary;
}
.calendar-header {
  border-bottom: 1px solid #ebebeb;
}
.calendar-prev,
.calendar-next {
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;

   
   &:before {
   		border-top: 2px solid #000;
	    border-left: 2px solid #000;
	    content:'';
	    display:inline-block;
	    height: 8px;
	    position:absolute;
	    top:50%;
	    -webkit-transform: translateY(-50%) rotate(-225deg);
		transform: translateY(-50%) rotate(-225deg);
	    width: 8px;
	}
}
.calendar-prev:before {
   -webkit-transform: translateY(-50%) rotate(-45deg);
	transform: translateY(-50%) rotate(-45deg);
}
.calendar-prev.calendar_blocked,
.calendar-prev.calendar_blocked:hover {
  background-image: none;
  cursor: auto;
}
.calendar-prev:hover {
  opacity:.85;
}
.calendar-next {
  
}
.calendar-next.calendar_blocked,
.calendar-next.calendar_blocked:hover {
  background-image: none;
  cursor: auto;
  opacity:.5;
}
.calendar-next:hover {
  opacity:.85;
}
.calendar-caption {
  color: #696969;
}
.calendar-caption:hover {
  color: #000000;
}
.calendar-caption.calendar_blocked,
.calendar-caption.calendar_blocked:hover {
  color: #696969;
  cursor: auto;
}
.calendar-head {
  background-color: #f6f6f6;
  padding-left: 6px;
  padding-right: 6px;
}
.calendar-head span {
  -webkit-box-shadow: inset 0 1px 0 #fbfbfb;
  box-shadow: inset 0 1px 0 #fbfbfb;
}
.calendar-body,
.calendar-months,
.calendar-years {
  padding: 6px;
}
.calendar-body span:hover,
.calendar-months span:hover,
.calendar-years span:hover {
  background-color: rgba($primary, .2);
}
.calendar-buttons {
  border-top: 1px solid #ebebeb;
}
