// ==========================================================================
// Product Accordeon
// ==========================================================================

// ==========================================================================
// Faqs
// ==========================================================================

// 1.Config


$productAccordeonTitleColor:     #A4A4A4;
$productAccordeonTitleIco:       #DDDDDD;
$productAccordeonTitleIcoHover:  #ccc;
$productAccordeonTitleActiveColor:     #ccc;
$productAccordeonTitleActiveIco:       #ccc;
$productAccordeonTitleActiveIcoHover:  $primary;

// 2.Base

.product-accordeon {
    margin:0 auto;

    &__block {
       margin-bottom:5px;
    }

    &__title {
        background-color: rgba(233, 233, 233, 0.5);
        color:#000;
        font-size:18px;
        line-height:1.2;
        cursor:pointer;
        transition:$transition-base;
        position:relative;
        padding:20px 50px 20px 15px;
        position:relative;
        
        @include media-breakpoint-up(md)  {
            font-size:20px;
        }

        &:after {
            @extend %pseudos;
            right:15px;
            @extend %v-align;
            font-family: "mitsubishi-font";
            content: '\e80c';
            color:#a9a9a9;
        }

        &:hover {
            color:$primary;

            &:after {
                color:$primary;
            }
        }

        &--active {            
            &:after {
                content: '\e80a';
            }
        }
    }

    &__content {
        display:none;
        padding:20px 15px;
        
    }



    &__text {
        font-size:16px;
        line-height:1.4;
        
        @include media-breakpoint-up(md)  {
            font-size:18px;
        }



        p {
            margin:0 0 20px;

            &:last-child {
                margin:0;
            }
        }
    }

}