// ==========================================================================
// Highlight Block
// ==========================================================================

// 1.Config


// 2.Base
.highlight-block{
	@extend %clearfix;

	&:first-child {
		margin-top:20px;
	}

	&__image {
		height: 240px;
		width: 100%;
		position: relative;

		@include media-breakpoint-up(md) { 
			float:left;
			width: 50%;
			height: 510px;
		}
		
		img {
			position:absolute;
			width:100%; 
			height:100%;
			object-fit: cover;
			top: 0;		
			left: 0;
	    }
	}

	&__content {
		padding:30px 15px;

		@include media-breakpoint-up(md)  { 
			float:left;
			width: 50%;
			height: 510px;
			padding:0 20px;
		}

		@include media-breakpoint-up(lg)  { 
			padding:0 40px;
		}
	}






	&__caption {
		//@extend %v-align-md;
		max-width: 600px;

		@include media-breakpoint-up(md)  { 
			  position: relative;
			  top: 50%;
			  transform: translateY(-50%);
		}
	}


	&__title {
		@include vw-font-size(18px);
		line-height: 1.1;
			margin:0 0 15px;

		@include media-breakpoint-up(md)  { 
			@include vw-font-size(21px);
		}

		@include media-breakpoint-up(lg)  { 
			@include vw-font-size(24px);
		}

		@include media-breakpoint-up(xl)  { 
			@include vw-font-size(32px);
		}

	}

	

	&__description {
		margin:0 0 30px;

		@include media-breakpoint-up(md)  { 
			margin:0 0 40px;
		}

		p {
			@include vw-font-size(16px);
			line-height: 1.6;		
			margin:0 0 20px;

			@include media-breakpoint-up(md)  { 
				@include vw-font-size(14px);
			}

			@include media-breakpoint-up(lg)  { 
				@include vw-font-size(15px);
			}

			@include media-breakpoint-up(xl)  { 
				@include vw-font-size(16px);
			}

			
			img {
				max-width:100%;
			}

		}
	}

	@include media-breakpoint-up(md)  { 
		&:nth-child(even) {
			.highlight-block__image {
				float: right;
			}

			.highlight-block__caption {
				float: right;
				text-align: right; 
			}
		}
	}
}