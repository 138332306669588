.product-table {
	padding:25px 20px;
	
	@include media-breakpoint-up(md) {
		padding:25px;
	}

	table { border-collapse: collapse; }
	th, td {
		border-right:1px solid #979797;
		border-top:1px solid #979797;
		padding:0 10px;

		&:first-child {
			border-top:0;
		}

		&:nth-child(2) {
			
		}
	}

	.v-align-top {
		vertical-align:top;
	}

	td { border-top:0; }

	tr:last-child {
		td:first-child { border-bottom:0; }
		td {
			border-bottom:1px solid #979797;
			padding-bottom:20px;
		}

	}

	&__item {
		figure {
			text-align:center;

			img {
				display:block;
				height:auto;
				margin: 0 auto 10px;
				max-width:240px;
				width:100%;
			}
		}

		.title {
			@include vw-font-size(18px);
			display:block;
			margin-bottom:10px;
			text-align:center;

			@include media-breakpoint-up(md) {
				@include vw-font-size(20px);
			}
		}

		.highlight {
			@include vw-font-size(16px);
			display:block;
			font-weight:300;
			margin-bottom:20px;
			margin-top:0;
			text-align:center;

			@include media-breakpoint-up(md) {
				@include vw-font-size(18px);
			}
		}
	}

	.small-devices {
		display:block;
		padding:0 0 10px;
		text-align:right;
		vertical-align:top;

		@include media-breakpoint-up(md) {
			min-width:150px;
		}
	}

	&__detail-info {
		@include vw-font-size(15px);
		vertical-align:top;

		@include media-breakpoint-up(md) {
			@include vw-font-size(16px);
		}

		span {
			display:block;
			margin-bottom:15px;
		}
	}
}