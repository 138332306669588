// ==========================================================================
// Text
// ==========================================================================

// 1.Config


// 2.Base
.text {
	@include vw-font-size(14px);
    font-weight:300;

    @include media-breakpoint-up(md)  {
        @include vw-font-size(16px);
    }
}

.text--medium {
	@include vw-font-size(16px);

    @include media-breakpoint-up(md)  {
        @include vw-font-size(18px);
    }
}

.semibold { font-weight:500; }

.text--big {
	@include vw-font-size(16px);
	line-height:1.4;

    @include media-breakpoint-up(md)  {
        @include vw-font-size(20px);
    }
}

.text--support-text {
    display:block;
    margin-top:40px;
}