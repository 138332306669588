.navigation {
	display:block;
	margin:20px auto;
	overflow:hidden;
	padding:0 15px;

	&__link {
		display:block;
		font-weight:bold !important;
		width:50%;

		@include media-breakpoint-up(md)  {
			width:40%;
		}

		i:before {
			width:auto;
		}

		> * {
			display:inline-block;
			vertical-align:top;
		}

		span {
			max-width:calc(100% - 50px);
		}
	}

	&__link--prev {
		float:left;
		transition:$transition-base;

		i {
			margin-right:10px;
		}

		&:hover {
			margin-left:-5px;
		}
	}

	&__link--next {
		float:right;
		transition:$transition-base;
		text-align:right;

		i {
			margin-left:10px;
		}

		&:hover {
			margin-right:-5px;
		}
	}
}