.product-components {

	&__block {
		overflow:hidden;
		padding:15px 0;

		@include media-breakpoint-up(sm) {
			padding:25px 0;
		}
	}

	&__figure {
		display:block;
		margin:0 auto;
		width:140px;

		@media (min-width:480px) {
			float:left;
			padding-right:20px;
		}

		img {
			display:block;
			height:auto;
			margin:0 auto;
			width:100%;
		}
	}

	&__info {
		@media (min-width:480px) {
			display:block;
			float:left;
			width:calc(100% - 150px);
		}
	}

	&__title {
		display:block;
		margin-bottom:10px;
		margin-top:20px;

		@media (min-width:480px) {
			margin-top:0;
		}

		@include media-breakpoint-up(sm) {
			margin-top:20px;
		}
	}

	&__text {

	}
}