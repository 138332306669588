// ==========================================================================
//News Box
// ==========================================================================

// 1.Config


// 2.Base

.news-list {
    @extend %clearfix;
    max-width:1236px;
    margin:40px auto 26px;
    padding:0 20px;

    .news-list-content {
        margin-left:-15px;
        margin-right:-15px;
        overflow:hidden;
    }
}

.news-box{
    $block: '.news-box';
    //height:410px;
    width: 100%;
    background:#000;
    overflow:hidden;
    border:10px solid #fff;
    float:left;
    position: relative;

    &--draft {
        &:after {
            background: $primary;
            border-radius: 50%;
            content: '';
            display: block;
            height: 25px;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 25px;
        }
    }

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    @media (min-width:480px) {
        width:50%;
    }

    @media (min-width:992px) {
        width:33.333%;
    }

    &:first-child {
        width:100%;

        @media (min-width:992px) {
            width:66.666%;
        }

        &:before {
            @media (min-width:480px) {
                padding-top:calc(50% - 10px);
            }

            @media (min-width:992px) {
                padding-top:calc(50% - 10px);
            }
        }

        .news-box__title {
            @include media-breakpoint-up(md) {
                @include vw-font-size(40px);
            }
        }
    }


    &__bg {
        z-index:-1;
         transition: 0.25s;
        transition-timing-function: ease-out;
        overflow:hidden;

        img {
            position:absolute;
            width:100%; 
            height:100%;
            object-fit: cover;
            top: 0;     
            left: 0;
            transition: 0.25s;
            transition-timing-function: ease-out;
        }
    }


    &__caption {
        padding:15px 15px 5px;
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.9));
    }


    &__category {
        @include vw-font-size(16px);
        margin:0;
        color:#fff;
        font-weight:400;

        @include media-breakpoint-up(md) {
            @include vw-font-size(18px);
        }
    }

    &__title {
        @include vw-font-size(18px);
        margin:5px 0 ;
        color:#fff;
        line-height: 1.3;
        font-weight:bold;

        @include media-breakpoint-up(md) {
            @include vw-font-size(24px);
        }
    }

    &__date {
        @include vw-font-size(14px);
        margin:10px 0 0;
        color:#fff;
        font-weight:300;
    }


    &__excerpt {
        @include vw-font-size(16px);
        color:#fff;
        font-weight:400;
        max-height:0;
        transition: .3s;
        transition-timing-function: ease-out;
        overflow:hidden;
        position:relative;
        padding-right:45px;
        opacity:0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        @include media-breakpoint-up(md) {
            @include vw-font-size(16px);
        }

        &:after {
            @extend %pseudos;
            right:0;
            @extend %v-align;
            font-size:18px;
            color:$primary;
            font-family: "mitsubishi-font";
            content: '\e801';
            font-weight:normal;

        }
    }


    &__link {
        position:absolute;
        top:0;
        height:100%;
        width:100%;
        left:0;
        z-index: 1;
       


        &:hover {
            #{$block}__excerpt { 
                //max-height:500px;
                max-height:66px;
                opacity: 1;
            }

            & + .news-box__bg {
                opacity:0.5;
                img {
                    transform:scale(1.1, 1.1);
                }
                
            }

        }
    }



  
   
}