// ==========================================================================
// Share
// ==========================================================================

// 1.Config


// 2.Base

.share{
    display:flex;
    align-items: center;
    //height:50px;    

    &__title {
        @include vw-font-size(16px);
        display:inline-block;
        font-weight:300;
        vertical-align:middle;

        @include media-breakpoint-up(sm) {
            @include vw-font-size(18px);
        }
    }

    &__list {   
        @extend %reset-list;
        display:inline-block;
        vertical-align:middle;

        li {
            display:inline-block;
            vertical-align:middle;
        }
    }
    
}

