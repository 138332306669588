// ==========================================================================
// Main Slide
// ==========================================================================

// 1.Config


// 2.Base

.main-slide {
    margin:0 auto;
    position:relative;
    background-color:#f0f0f0;
    color:#fff;

    .swiper-container.disabled {
        .swiper-button-prev, .swiper-button-next {
            display:none;
        }
    }
    .swiper-pagination.disabled {
        display:none;
    }

    &.main-slide--fade {
        .main-slide__item {
            background:#ccc;
            opacity:0;
            position:relative;

            &:before {
                background-image:linear-gradient(251deg, rgba(13, 18, 34, 0), rgba(13, 18, 34, 0.81));
                content:'';
                display:block;
                height:100%;
                left:0;
                opacity:.87;
                position:absolute;
                top:0;
                width:100%;
                z-index:1;
            }
        }
    }
    

    &__inner {
        padding: 0 40px 0;
        height:500px;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        align-items: center;
        position:relative;
        z-index:1;

        @include media-breakpoint-up(md)  {
            height:680px;
            padding: 40px 40px 40px;
        }

        .swiper-container {
            height:100%;
        }
    }

    &__item-info {
        max-width: 570px;
        text-align:left; 

        @include media-breakpoint-up(md)  {
            padding-left:40px;
        }
    }


    &__item-bg {
        z-index:-1;

        img {
            position:absolute;
            width:100%; 
            height:100%;
            object-fit: cover;
            top: 0;     
            left: 0;
        }   
    }

    &__item-title {
        max-width:900px;
        position:relative;

        @include media-breakpoint-up(md)  {
            display:inline-block;
        }
    }

    &__item-text {
        
    }

    &__item-cta a {
        background-color:#fff;
    }

    .swiper-pagination-bullet {
        opacity:1;
        background:#fff;
    }
    .swiper-pagination-white .swiper-pagination-bullet-active {
        background-color:$primary;
    }  
}