.content-fancy {
	cursor:default !important;
	max-width:100%;
	padding:30px 20px;
	width:100%;

	@include media-breakpoint-up(sm) {
		width:400px;
	}

	@include media-breakpoint-up(md) {
		padding:60px 120px;
		width:865px;
	}

	.custom-scrollbar { max-height:80vh; }

	.form {
		margin:0 auto;

		.form-group:last-child {
			margin-bottom:0;
		}

		.form-group--center { margin-bottom:0; }
	}
}

body.IE {
	.content-fancy .custom-scrollbar {
		max-height:100%;
	}
}