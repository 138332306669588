// ==========================================================================
// CTAs
// ==========================================================================

// 1.Config


// 2.Base
.cta {
	@include vw-font-size(16px);

    @include media-breakpoint-up(md)  {
        @include vw-font-size(18px);
    }
}