// ==========================================================================
// Distributors
// ==========================================================================

// 1.Config


// 2.Base
.distributors{

   &__block {
        margin-bottom:20px;
   }


   &__title {
        margin:0 0 30px;
        font-weight:bold;
   }

   &__country-list {
        @extend %reset-list;
        @extend %clearfix;

        li {
            float:left;
            width:50%;
            padding-right:20px;
            margin-bottom:35px;

            @include media-breakpoint-up(md)  {
                width:33.333%;
                padding-right:20px;
            }

            a {
                color:#afafaf;
                display:block;
                border-bottom: solid 1px #afafaf;
                font-size: 18px;
                font-weight: bold;
                padding:0 0 15px;

                @include media-breakpoint-up(md)  {
                    font-size: 24px;
                }

                &:hover {
                    color:#000;
                }
            }
        }
   }

   &__list {
        @extend %reset-list;
        @extend %clearfix;

        @media (min-width:480px) {
            margin-left:-15px;
            margin-right:-15px;
            padding:0 15px;
        }

        > li {
            padding-right:20px;
            margin-bottom:35px;
            vertical-align:top;

            @media (min-width:480px) {
                display:inline-block;
                width:49%;
            }

            @include media-breakpoint-up(md)  {
                width:32%;
                padding-right:20px;
            }

            > a {
                display:block;
                font-size: 18px;
                font-weight: bold;
                padding:0 0 15px;

                @include media-breakpoint-up(md)  {
                    font-size: 24px;
                }
            }
        }
   }
}