body.error .main-section--error {
	width:100%;

	@include media-breakpoint-up(md) {
		display:table;
		min-height:75vh;
	}

	.article {
	    width: 100%;

	    @include media-breakpoint-up(md) {
	    	display: table-cell;
	    	vertical-align: middle;
		}

	    .btn { text-decoration:none; }
	}
}