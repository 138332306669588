// ==========================================================================
// Global Config
// ==========================================================================


//General
$version-statics: "1.0";
$url-statics: "../";
$image-path: "images";


//Colors
$primary:       #DB0000;
$secondary:     #000;

$body-color:  #000;
$body-bg:     #000;

$white:       #fff;
 

//Fonts
$font-regular:                'Open Sans', serif!default;
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-base:            $font-regular;
$font-title:                'Courgette', serif!default; 

//Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1450px
) !default;


//Grid
$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;


//Container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
) !default;


//Transition
$transition-base:         all .2s ease-in-out !default;






