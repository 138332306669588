// ==========================================================================
// Product Gallery
// ==========================================================================

// 1.Config


// 2.Base

.product-actions {
  	margin-top:30px;
  	margin-bottom:35px;
	@extend %clearfix;

	&__list {
		
		@extend %reset-list;
		

		@include media-breakpoint-up(md)  {
			display:flex;
			align-items: center;
			float:left;
		}

		li {
			margin-right:15px;
			display:inline-block;
			&:last-child {
					display:block;
					margin-top:20px;

				@include media-breakpoint-up(md)  {
					margin-right:0;
					margin-left:10px;
					margin-top:0;
				}
			}

			&:first-child {
				margin-left:0;
			}

			a {
				margin:0;
			}

		}
	}



	&__social {
		margin-top:10px;

		@include media-breakpoint-up(md)  {
			float:right;
			margin-top:0;
		}

	}

}