.main-section {
	max-width:1016px;
    margin:0 auto;
    padding:35px 20px;

    @include media-breakpoint-up(sm)  {
        padding:65px 20px 35px;
    }
}

.main-section--align-center {
	text-align:center;
}