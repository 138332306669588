.align-center {
	display:block;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
}


.bg-gray {
	background:#e9e9e9;
}


body.overflow {
	height:100%;
	overflow:hidden;
}