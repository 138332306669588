.gs18-Breadcrumb {
	max-width: none;
	z-index:1;
}

.gs18-HeaderPulldown__Header {
	padding-right:25px;
}

header {
	.gs18-HeaderNav__Text {
		@include vw-font-size(16px);
		padding-right:25px;
		position:relative;

		&:before {
			border-top:2px solid #000;
			border-left:2px solid #000;
			height:10px;
			width:10px;
		}

		.down-arrow {
		    border-left: 2px solid #000;
		    border-top: 2px solid #000;
		    content: "";
		    display:none;
		    font-weight: 700;
		    height: 8px;
		    margin-top:-1px;
		    position: absolute;
		    right: 11px;		    
		    top: 50%;
		    -webkit-transform: translateY(-50%) rotate(-135deg);
		    transform: translateY(-50%) rotate(-135deg);
		    width: 8px;

		    @media (min-width:981px) {
		    	display:inline-block;
		    }
		}

		&:hover .down-arrow {
			border-color:#f50f10;
		}
	}

	@media only screen and (max-width: 980px) {
		.gs18-HeaderPulldown__Title {
		    @include vw-font-size(20px);
		}
	}

	.gs18-Header__Search { display:none; }
	.gs18-Header__Inner { padding-right:0; }
	
	@media (min-width:981px) {
		.gs18-HeaderLang .gs18-HeaderLang__List { left:-85px; }
	}
}


.gs18-Header__Search .gs18-Header__SearchBtn {
	background-size:20px;
	width:70px;

	&:focus { outline:none; }

	img {
		margin-left:1px;
		width:20px;
	}
}

.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput,
.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input {
	@include vw-font-size(16px);
	line-height:30px;
	padding:0 10px;

	&:focus {
		outline:none;
	}
}

.gs18-Header__Search .gs18-SearchBox__Submit, .gs18-Header__Search .mf_finder_searchBox_submit {
	&:focus { outline:none; }
}

.gs18-Header__Search.is-open .gs18-Header__SearchInner > button::after {
	margin-left:1px;
	margin-top:4px;
}

.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text.is-open {
	.down-arrow {
		border-color:#fff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	&:hover {
		.down-arrow { border-color:#f50f10; }
	}
}


.gs18-HeaderLang {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}



	.gs18-HeaderLang {
	    height: 100%;
	}

	.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple {
	    font-size: 14px;
	}

	.gs18-HeaderLang .gs18-HeaderLang__Stage {
	    @media only screen and (min-width: 981px) {
	    	height: 100%;
	    	position: relative;
	    }
	}

	.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang {

		@media only screen and (min-width: 981px) {
		    display: block;
		    box-sizing: border-box;
		    padding: 34px 20px 32px 0;
		    width: 69px;
		    height: 85px;
		    text-align: center;
		    color: #000;
		    text-decoration: none;
		    position: relative;
		}
	}

	.gs18-HeaderLang .gs18-HeaderLang__List {
	    position: absolute;
	    display: none;
	    width: 215px;
	    margin: 0;
	    padding: 0;
	    left: 0;
	    overflow: hidden;
	    background: #000;
	    list-style: none;
	    font-size: 18px;
	    font-weight: 500;

    	.gs18-HeaderLang .gs18-HeaderLang__List>li {
		    border-top: solid 1px #272727;

		    .gs18-HeaderLang .gs18-HeaderLang__List>li>a {
			    display: block;
			    padding: 15px;
			    color: #fff;
			    text-decoration: none;
			}
		}
	}

	.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang/*:before*/ {
		/*border-style: solid;
	    border-width: 7px 4px 0 4px;
	    border-color: #000 transparent transparent transparent;
	    content: '';
	    display: block;
	    height: 0;
	    position: absolute;
	    width: 0;

	    @media only screen and (max-width: 980px) {

		    top: 29px;
		    right: 14px;
		    width: 0;
		    height: 0;
		    border-style: solid;
		    border-width: 7px 4px 0 4px;
		    border-color: #000 transparent transparent transparent;
		}

		@media only screen and (min-width: 981px) {
		    top: 39px;
		    right: 20px;
		}*/
		.down-arrow {
		    border-left: 2px solid #000;
		    border-top: 2px solid #000;
		    content: "";
		    display:none;
		    font-weight: 700;
		    height: 8px;
		    margin-top:-1px;
		    position: absolute;
		    right: 20px;		    
		    top: 50%;
		    -webkit-transform: translateY(-50%) rotate(-135deg);
		    transform: translateY(-50%) rotate(-135deg);
		    width: 8px;

		    @media (min-width:981px) {
		    	display:inline-block;
		    }
		}
	}

	.gs18-HeaderLang .gs18-HeaderLang__Lang>abbr {
	    text-decoration: none;
	}

	.gs18-HeaderLang .gs18-HeaderLang__Lang {
	    font-weight: 500;

	    @media only screen and (max-width: 980px) {
	    	display: block;
		    box-sizing: border-box;
		    padding: 24px 14px 22px 0;
		    width: 59px;
		    height: 65px;
		    text-align: center;
		    font-size: 14px;
		    font-weight: 500;
		    color: #000;
		    text-decoration: none;
		    position: relative;
	    }
	}


	.gs18-HeaderLang .gs18-HeaderLang__List {
        position: absolute;
        display: none;
        width: 100%;
        margin: 0;
        padding: 0;
        left: 0;
        overflow: hidden;
        background: #000;
        list-style: none;
        font-size: 18px;
        font-weight: 500;

        @media only screen and (min-width: 981px) {
        	width: 154px;
		}
    }

    [dir=rtl] .gs18-HeaderLang .gs18-HeaderLang__List {
        left: auto;
        right: 0
    }

    .gs18-HeaderLang .gs18-HeaderLang__List>li {
        border-top: solid 1px #272727
    }

    .gs18-HeaderLang .gs18-HeaderLang__List>li>a {
        display: block;
        padding: 15px;
        color: #fff;
        text-decoration: none
    }

    .gs18-HeaderLang .gs18-HeaderLang__List>li>a:active,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:focus,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:hover {
        color: #f50f10
    }

    .gs18-HeaderLang[data-js-gs18-lang-switch="2"] .gs18-HeaderLang__List {
        display: none
    }

    .gs18-HeaderLang.is-open {
    	.gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__List {
	        display: block
	    }

	    .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang {
	    	background: #000;
    		color: #fff;

	    	/*&:before {
				border-top-color: #fff;
				-webkit-transform: rotate(180deg);
			    transform: rotate(180deg);
			}*/
			.down-arrow {
				border-color:#fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}

	    }
	}


body.gs18-HeaderFog {
	&:after {
	    content: "";
	    display: none;
	    position: fixed;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: #000;
	    z-index: 1;
	    opacity: .95;

	    @media only screen and (max-width: 980px) {
	    	display:block;
	    }
	}

	.gs18-Header.is-flyout {
		@media only screen and (max-width: 980px) {
			height:100%;
			overflow:auto;
		}
	}
}

footer {
	&.gs18-Footer { padding-top:0; }

	.gs18-Footer__SNSLinkedin{background:url(../images/mitsubishi/icon/linkedin-R.svg) no-repeat left top;background-size:auto 20px}
	.gs18-Footer__SNSInstagram{background:url(../images/mitsubishi/icon/instagram-R.svg) no-repeat left top;background-size:auto 20px}

	.gs18-Footer__Inner {
		padding-top:30px;

		.gs18-Footer__Title { margin-top:0; }
	}

	.gs18-Footer__SNS > li img {
		width:20px;
	}

	.gs18-Footer__Legal > li > a.is-current {
		color:#f50f10;
	}
}

.sitemap {
	.gs18-SitemapList>.gs18-LinkListItem {
	    width: 100%;
	    margin: 0 0 0 30px;
	    padding: 15px 0;
	}
	
	.gs18-SitemapList>.gs18-LinkListItem .gs18-Link {
	    display: inline-block;
	    line-height:26px;
	    padding-left: 30px;
	    position:relative;
	}

	.gs18-LinkListItem .gs18-Link:before {
		left:0;
		top:0;
	}

	.gs18-SitemapList>.gs18-LinkListItem .gs18-LinkList--Horizontal {
	    margin-left: 30px;
	}

	.gs18-LinkListItem--fullwidth { width:100%; }

	.gs18-LinkList--Size2of2 {

	}
}

.gs18-HeaderNav__Menu .media-supply-policy-link { display:inline-block; }
.gs18-HeaderNav__Menu .gs18-HeaderNav__Item:first-child .media-supply-policy-link { display:inline-block; }