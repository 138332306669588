.product-comparator {
	background:#fff;
	height:100%;
	left:0;
	overflow-y:auto;
	position:fixed;
	top:0;
	width:100%;
	z-index:2;

	.fancybox-button {
		height:35px;
		width:35px;

		@include media-breakpoint-up(md) {
			height:55px;
			width:55px;
		}
	}

	&__close {
		@include vw-font-size(16px);
		background:#f6f6f6;
		cursor:pointer;
		display:block;
		height:35px;
		padding:0 7px;
		position:relative;
		text-align:right;
		z-index:2;

		@include media-breakpoint-up(md) {
			@include vw-font-size(20px);
			height:60px;
			padding:0 15px;
		}
	}

	.product-list {
		margin:25px auto;
		max-width:100%;
		overflow:hidden;

		@include media-breakpoint-up(md) {
			margin:50px auto;
		}

		&__item {
			display:block;
			padding:0 15px 20px;
			width:100%;

			@include media-breakpoint-up(md) {
				padding:0 20px 20px;
			}
		}

		&__top {
			margin-bottom:20px;
			padding:0 20px;
			text-align:center;

			figure {

				img {
					display:block;
					height:auto;
					margin: 0 auto 10px;
					max-width:240px;
					width:100%;
				}
			}

			.title {
				@include vw-font-size(18px);

				@include media-breakpoint-up(md) {
					@include vw-font-size(20px);
				}
			}

			.highlight {
				@include vw-font-size(16px);
				margin-top:0;

				@include media-breakpoint-up(md) {
					@include vw-font-size(18px);
				}
			}
		}

		&__detail-info {
			@include vw-font-size(15px);

			@include media-breakpoint-up(md) {
				@include vw-font-size(16px);
			}
		}

		&__block {
			margin-bottom:15px;

			@include media-breakpoint-up(md) {
				margin-bottom:20px;
			}

			.small-devices {
				display:block;
				margin-bottom:5px;
			}


			span {

			}

			&:last-child { margin-bottom:0; }
		}

		.swiper-slide {
			border:1px solid #979797;
			height:auto;
			width:100%;

			@include media-breakpoint-up(sm) {
				border-left:0;
				float:left;
				width:50%;

				&:first-child {
					border-left:1px solid #979797;
				}
			}

			@include media-breakpoint-up(md) {
				width:33.3%;
			}

			@include media-breakpoint-up(lg) {
				width:25%;
			}
		}

		.swiper-container {
			padding:0 40px;
		}

		.swiper-button-slide {
			background:#fff;
			color:#000;
			height:100%;
			position:fixed;
			top:15px;
			width:40px;
			z-index:2;

			i {
				display:block;
				position:absolute;
				top:50%;
				transform:translate(0,-50%);
				width:100%;
			}
		}

		.swiper-button-prev {
			left:0;
			width:38px;

			&:focus { outline:0; }
		}

		.swiper-button-next {
			right:0;

			&:focus { outline:0; }
		}
	}

	.product-list.compare-3-product {
		.swiper-slide {
			@include media-breakpoint-up(sm) {
				width:50%;
			}

			@include media-breakpoint-up(md) {
				width:33.3%;
			}
		}
	}

	.product-list.compare-2-product {
		.swiper-slide {
			@include media-breakpoint-up(sm) {
				width:50%;
			}
		}
	}

	.product-list.compare-1-product {
		.swiper-slide {
			border:1px solid #979797;	
			width:100%;
		}
	}
}