// ==========================================================================
// Top Header
// ==========================================================================

// 1.Config


// 2.Base

.top-header{
    //max-width:1920px;
    margin:0 auto;
    position:relative;
    background-color:#f0f0f0;
    
    &:before {
        //background-image: radial-gradient(circle at 52% 50%, #ffffff, #000000);
        background:rgba(0,0,0,.5);
        content:'';
        display:block;
        height:100%;
        left:0;
        mix-blend-mode: multiply;
        opacity:.4;
        position:absolute;
        top:0;
        transition:$transition-base;
        width:100%;
        z-index:1;
    }

    &__inner {
        padding: 0 40px 0;
        height:200px;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        align-items: center;
        position:relative;
        z-index:1;

        @include media-breakpoint-up(md)  {
            height:280px;
        }

        @media (min-width:881px) {
            padding: 125px 40px 40px;
        }
    }

    &__title {
        color:#fff;
        margin:0;
        text-align:center;
        //text-shadow:1px 0px 10px rgba(0, 0, 0, 0.1);
        width:100%;
    }

    &__bg {
        z-index:-1;

        img {
            position:absolute;
            width:100%; 
            height:100%;
            object-fit: cover;
            top: 0;     
            left: 0;
        }

    }



    &--short {
        padding-top:0px;
        background:#000;

        @include media-breakpoint-up(lg)  {
            padding-top:100px;
        }
    }   
}

.top-header--no-photo {
    .top-header__title { color:#000; }
}