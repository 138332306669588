.article {
	
	.highlight {
		@include vw-font-size(17px);
		@include vw-line-height(24px);

		@include media-breakpoint-up(sm) {
			@include vw-font-size(20px);
			@include vw-line-height(27px);
		}
	}

	p {
		@include vw-font-size(16px);
		@include vw-line-height(27px);
	}

	.product-table {
		margin-bottom:30px;
	}

	a {
		text-decoration:underline;
	}
}