.m-lightbox-overlay {
	background:rgba(0,0,0,.8);
	bottom:0;
	left:0;
	overflow:hidden;
	position:fixed;
	right:0;
	top:0;
	z-index:9999;
	text-align:center;
}

body.overflow, body.lock-position {
	height:100%;
	overflow:hidden;
}

.m-lightbox, .m-alert {
	background:#fff;
	left:50%;
	max-width:600px;
	padding:25px 20px;
	position:absolute;
	text-align:center;
	top:50%;
	transform:translate(-50%,-50%);
	width:95%;

	@include media-breakpoint-up(md)  {
		padding:50px;
	}

	.form-group-buttons {
		margin-top:20px;

		@include media-breakpoint-up(md) {
			margin-top:30px;
		}
	}

	&__close {
		-webkit-appearance:none;
		border:none;
		color:#afafaf;
		cursor:pointer;
		font-size:25px;
		outline:none;
		padding:0;
		position:absolute;
		right:5px;
		top:5px;

		&:hover { color:#000; }

		&:focus { outline:none; }
	}

	&__title {
		@include vw-font-size(18px);
		@include vw-line-height(24px);
		color:$primary;
		display:block;
		margin:0 auto 20px;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(21px);
			@include vw-line-height(30px);
		}
	}

	&__text {
		@include vw-font-size(16px);
		@include vw-line-height(21px);
		color:#000;
		display:block;
		margin:0 auto 20px;
		word-wrap:break-word;
		
		@include media-breakpoint-up(md) { 
			@include vw-font-size(18px);
			@include vw-line-height(24px);
		}
	}

	.btn {
		cursor:pointer;
	}
}
