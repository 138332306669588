// ==========================================================================
// Buttons
// ==========================================================================

// 1.Config



// 2.Base

.btn {
  @include vw-font-size(18px);
  border-radius: 0;
  text-decoration: none;
  box-sizing: border-box;
  color: #000;
  //background:$secondary;
  position:relative;
  display: inline-block;
  min-width:auto!important;
  font-weight: 700;
  text-align: center;
  padding: 11px 13px 9px;
  transition: $transition-base;
  border:2px solid #000;

  @include media-breakpoint-up(md)  { 
    
  }


  @include media-breakpoint-up(md)  { 
   
  }


  &:hover {
    //border:none;
    text-decoration: none;
    color:$primary;
    background:#fff;
    border:2px solid #fff;


  }


  &:focus, &:active { outline:0; }



    &--video {
        background-color:#fdf2f3;
        border-color:#fdf2f3;
        color:#e7676f;
        padding: 17px 20px 17px 60px;
        position: relative;

        &:hover {
            background-color:#fdf2f3;
            border-color:#fdf2f3;
            color:$primary;    
        }

         &:before {
            @extend %pseudos;
            font-size:30px;
    
            font-family: "mitsubishi-font";
            content: '\e80b';
             font-weight:normal;   


            position:absolute;
            left:15px;
            @extend %v-align;
            transition:$transition-base;
        }
    }



    &--link {
        padding-right: 60px;

        &:after {
            font-family: "mitsubishi-font";
            content: '\e801'; 
            //@extend %v-align;
            transition:$transition-base; 
            color:#000;  
            font-size:18px;
            position:absolute;
            right:20px;
        }

        &:hover {
            &:after {
                right:10px;
                color:$primary;
            }
            

        }
    }
  
}


.btn-large {
  padding: 11px 25px 9px;
}


//button compare

.btn-compare {
    position:relative;
    visibility:hidden;
    padding-left:32px;
    font-weight:400;

    &:before {
        @extend %pseudos;
        font-size:14px;
        line-height:16px;
        padding-left:1px;
        font-family: "mitsubishi-font";
        content: '\e802';
        color:#fff;
        height:20px;
        width:20px;
        border:2px solid #000;
        position:absolute;
        left:0;
        background:#fff;
        @extend %v-align;
        transition:$transition-base;
    }

    &.isActive:before{
        color:$primary;
    }

    &.disabled{
        pointer-events:none;
        opacity:0.2;
    }
}


.btn--red {
    background:#f50f10;
    border-color:#f50f10;
    color:$white;
    font-weight:bold;

    &:hover {
        background:$white;
        border-color:#f50f10;
        color:#f50f10;
    }
}


