// ==========================================================================
// Product Slide
// ==========================================================================

// 1.Config


// 2.Base

.product-slide {
	margin-top:30px;

	.swiper-wrapper {
		max-width:1060px;
	}

 	.swiper-slide {
  		padding:0 15px;
  	}

  	&__item {

      text-align: center;

  	}


  	&__item-image {
      height:280px;
  	margin:0 auto 30px;
  		img {
  			height:100%;
  		}
  	}

    &__info {

    }

  	&__item-title {
  		@include vw-font-size(18px);
  		font-weight: bold;
  		line-height: 1;
  		margin:0 0 5px;

      @include media-breakpoint-up(md)  {
        @include vw-font-size(26px);
      }
  	}

  	&__item-code {
  		@include vw-font-size(15px);
		font-weight: 400;
		line-height: 1.4;
		margin:0 0 5px;

      @include media-breakpoint-up(md)  {
        @include vw-font-size(18px);
      }
  	}

  	&__item-description {
  		font-size: 16px;
  		line-height: 1.5;
  		margin:0 0 5px;
  	}

  	&__item-list {
  		@extend %reset-list;
        text-align:center;
  		font-size: 16px;
  		line-height: 1.5;
  		margin:10px 0 0;

  		li {
  			margin-bottom:5px;
  		}
  	}
}


.swiper-button-slide {
  width:56px;
  height:56px;
  background:#db0000;
  text-align:center;
  line-height: 56px;
  color:#fff;
  font-size: 24px;

  &:focus { outline:0; }
}

  .product-extended__block.disabled {
    
      .swiper-button-prev, .swiper-button-next {
          display:none;
      }
  }
