
.download-block {
	margin-bottom:30px;

	@include media-breakpoint-up(md)  {
		margin-bottom:80px;
	}

	&:last-child {
		margin-bottom:0;
	}

	&__title {
		display:block;
		clear:both;
	}

	&__description {

	}
}


.download-table {
	clear:both;
	margin:0 auto;
	overflow:hidden;
	padding:0;

	&__row {
		@extend %clearfix;
		padding: 15px 10px;

		@include media-breakpoint-up(md)  {
			float:left;
			width:50%;


			&:nth-child(even) {
				padding-left:15px;
				padding-right:0;
			}

			&:nth-child(odd) {
				padding-left:0;
				padding-right:15px;
			}
		}
	}

	&__col {
		float:left;
		font-size: 16px;
		line-height:1.6;
		padding-right:15px;
		position:relative;

		&:last-child {
			padding-right:0;
		}
	}

	&__col--ref {
		width:100%;

		@media (min-width:480px) {
			width:140px;
		}
	}

	&__col--system {
		width:calc(100% - 40px);

		@media (min-width:480px) {
			width:calc(100% - 180px);			
		}
	}

	&__col--download {
		width:40px;

		a.disabled {
			pointer-events:none;
			opacity:0.2;
		}
	}

	&__title {
		display:block;
		font-size:16px;
		line-height:40px;
		margin:0 auto;

		@media (min-width:480px) {
			font-weight:300;
		}

		@include media-breakpoint-up(md)  {
			font-size:18px;
		}
	}

	.select2 {
		display:block;
		max-width:100% !important;
		width:95% !important;
	}

	.ico-download {
		line-height:40px;
	}
}
