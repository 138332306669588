// ==========================================================================
// Product Gallery
// ==========================================================================

// 1.Config


// 2.Base

.product-gallery {
    margin-top:30px;
   

	&__main {
		text-align:center;
        margin-bottom:30px;

        .swiper-wrapper {
            -webkit-align-items: center;
            align-items: center;
        }
	}

	&__image {
		margin:0 auto;
		width:100%;

        @media (min-width:390px) {
            width:345px;
        }
	}

    &__thumbs {
        box-sizing: border-box;
        padding:0;

        .swiper-wrapper {
            display:block;
            text-align:center;
        }

        .swiper-slide {
            display:inline-block;
            width: 50px;
            height: 50px;
            border: solid 1px #e9e9e9;
            cursor:pointer;
            background-size: cover;
            background-position: center;
            position:relative;

            img {
                position:absolute;
                width:100%; 
                height:100%;
                object-fit: cover;
                padding:2px;
                top: 0;     
                left: 0;
            }
        }

        .swiper-slide-thumb-active {
            border: solid 1px $primary;
        }
  
    }
    
}