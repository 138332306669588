// ==========================================================================
// Product Grid
// ==========================================================================


.product-grid {
	@extend %clearfix;

	.tile-item {
		height:300px;


		 @include media-breakpoint-up(md)  {
			width:50%;
			float:left; 
			height:400px; 
			margin:0;
		}
	}
}